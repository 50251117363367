import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { EbCarouselComponent } from '@e-bilet/ui-carousel';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { UiScrollbarModule } from '@e-bilet/ui-scrollbar';
import { EbArticleCardContainerComponent } from '../lib/article-card-container/article-card-container.component';
import { EbArticleCardComponent } from './article-card/article-card.component';
import { IconComponent } from '../../../ui-icons/src/lib/icon/icon.component';
import { EbCarouselItemDirective } from '../../../ui-carousel/src/lib/carousel-item.directive';

@NgModule({
    imports: [
        CommonModule,
        UiButtonsModule,
        UiPipeModule,
        RouterModule,
        EbCarouselComponent,
        EbCarouselItemDirective,
        UiScrollbarModule,
        IconComponent,
        UiScrollbarModule,
    ],
    declarations: [EbArticleCardComponent, EbArticleCardContainerComponent],
    exports: [EbArticleCardComponent, EbArticleCardContainerComponent],
})
export class UiArticleCardModule {}

<a class="article-card" [ngClass]="'article-card--' + size()" [href]="article()?.linkTo">
  <div class="article-card__img-frame eb-img-frame eb-img-frame--with-desc" [ngClass]="{ 'eb-skeleton': skeleton() }">
    @if (article() && !skeleton()) {
      <img class="article-card__img w-full h-full" [src]="imgSrc()" [alt]="article()!.title" />
    }
  </div>
  <div class="article-card__desc" [ngClass]="{ 'article-card__desc--overlay': size() === 'extra-large' }">
    @if (size() !== 'extra-large') {
      <div class="article-card__category flex">
        @for (tag of article()?.tags ?? [] | slice: 0 : amountOfTagsVisible(); track $index) {
          <ng-template
            [ngTemplateOutlet]="articleTag"
            [ngTemplateOutletContext]="{ tag: tag, skeleton: skeleton(), tagAmount: article()?.tags?.length }"
          />
        }

        <p class="eb-size--tiny mb-0 flex align-items-center mt-1 md:mt-0" [ngClass]="{ 'ml-auto': article()?.tags?.length }">
          @if (!skeleton()) {
            <eb-icon class="text-base" key="calendar" />
          }
          <span class="ml-1" [ngClass]="{ 'eb-skeleton': skeleton() }" [style]="skeleton() ? 'width: 80px' : ''">{{
            article()?.date | ebDate
          }}</span>
        </p>
      </div>
    } @else {
      @if (!skeleton()) {
        <div>
          <p class="eb-size--tiny mb-0 flex align-items-center mt-1 md:mt-0">
            <eb-icon class="text-base article-card__date-icon" key="calendar" />
            <span class="ml-1 article-card__date">{{ article()?.date | ebDate }}</span>
          </p>
          <div class="article-card__tags--extra-large">
            @for (tag of article()?.tags ?? [] | slice: 0 : amountOfTagsVisible(); track $index) {
              <ng-template
                [ngTemplateOutlet]="articleTag"
                [ngTemplateOutletContext]="{ tag: tag, skeleton: skeleton(), isExtraLarge: true }"
              />
            }
          </div>
        </div>
      }
    }

    <div>
      @if (!skeleton() || size() !== 'extra-large') {
        <p class="h6 article-card__title mb-1" [ngClass]="{ 'eb-skeleton': skeleton() }">{{ article()?.title }}</p>
      }
    </div>
    @if (skeleton() && size() !== 'extra-large') {
      <p class="mb-1 eb-skeleton"></p>
    }
  </div>
</a>

<ng-template #articleTag let-tag="tag" let-skeleton="skeleton" let-tagAmount="tagAmount" let-isExtraLargeArticle="isExtraLarge">
  <span
    class="eb-tag block text-white font-light w-min px-2"
    [ngClass]="{
      'eb-skeleton': skeleton,
      'mr-2': tagAmount > 1,
      'mt-1 max-w-10rem sm:max-w-28rem': isExtraLargeArticle,
      'max-w-15rem': !isExtraLargeArticle
    }"
    [style]="{ 'background-color': tag?.backgroundColor, color: tag?.foregroundColor, width: skeleton ? '70px' : 'auto' }"
    [innerHTML]="tag?.text"
  ></span>
</ng-template>

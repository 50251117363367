import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { Article } from '../../../../../apps/portal/src/app/rest-api/models/article.interface';

@Component({
    selector: 'eb-article-card-container',
    templateUrl: './article-card-container.component.html',
    styleUrls: ['./article-card-container.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbArticleCardContainerComponent {
    articles: InputSignal<Article[]> = input.required();
    showCarousel: InputSignal<boolean> = input(false);
    isMegaMenu: InputSignal<boolean> = input(false);
    loading: InputSignal<boolean> = input(true);
}

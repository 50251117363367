<!-- Many articles -->
@if(showCarousel()) {
  @if(!loading()) {
    <eb-carousel>
      @for(article of articles(); track i; let i = $index) {
        <eb-article-card
          [article]="article"
          [size]="'normal'"
          ebCarouselItem
          ebIntersection
        />
      }
    </eb-carousel>
  } @else {
    <eb-carousel class="grid-section__content">
      <eb-article-card *ebRepeat="6" [skeleton]="true" [size]="'normal'" ebCarouselItem />
    </eb-carousel>
  }
} @else {

  <!-- Two large articles -->
   @if (!isMegaMenu()) {
      @if(articles().length === 2) {
        <div ebScrollbar class="article-card-container--large-articles scroll-hide overflow-x-auto">
          @if(!loading()) {
            @for(article of articles(); track i; let i = $index) {
              <eb-article-card
                [article]="article"
                [size]="'large'"
              />
            }
          } @else {
            <eb-article-card
              *ebRepeat="2"
              [skeleton]="true"
              [size]="'large'"
            />
          }
        </div>
      } @else {

        <!-- One extra large article -->
        @if(!loading()) {
          <eb-article-card
            class="article-card-container--extra-large-articles"
            [article]="articles()[0]"
            [size]="'extra-large'"
          />
        } @else {
          <eb-article-card
            [skeleton]="true"
            [size]="'extra-large'"
          />
        }
      }
   } @else {

      <!-- Two small articles (mega menu) -->
      <div class="article-card-container--small-articles">
        @if(!loading()) {
          @for(article of articles(); track $index) {
            <eb-article-card
              class="article-card--small"
              [article]="article"
              [size]="'small'"
            />
          }
         } @else {
          <eb-article-card
            [skeleton]="true"
            [size]="'small'"
          />
         }
      </div>
   }
}
